import { render, staticRenderFns } from "./NotifBar.vue?vue&type=template&id=aee00fa6&scoped=true&"
import script from "./NotifBar.vue?vue&type=script&lang=js&"
export * from "./NotifBar.vue?vue&type=script&lang=js&"
import style0 from "./NotifBar.vue?vue&type=style&index=0&id=aee00fa6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aee00fa6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
